import React from 'react';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { rem, responseErrors } from '@utils';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { ButtonTextArrow } from '../../components/text-arrow-button';
import { APIObjectErrorsProps, RecoveryPasswordTypeNewProps } from '@interfaces/Services';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DEFAULTS } from './DEFAULTS';
import { useAuth } from '@hooks/auth';
import { Feedback } from '@components';
import { useNavigate } from 'react-router-dom';
import { routes } from '@static/routes.list';

interface RecoveryPasswordForm extends RecoveryPasswordTypeNewProps {
    confirmPassword: string;
}

const RecoveryForm: React.FC = () => {
    const { typography, palette } = useTheme();
    const { css } = sx();

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });
    const [cardErrors, setCardErrors] = React.
        useState<APIObjectErrorsProps>({
            listErrors: DEFAULTS.API_OBJ,
        });
    const errorKeys = Object.keys(cardErrors);
    const auth = useAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<RecoveryPasswordForm>({
        mode: 'onSubmit',
    });
    const handleClose = () => setOpen(false);

    const onSubmit: SubmitHandler<RecoveryPasswordForm> = async (data) => {
        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });

        const response = await auth.recoveryPassword.typeNew({
            password: data.password,
            token: '',
        });

        if (response?.error) {
            setIsLoading(DEFAULTS.loading);
            setOpen(true);
            setResponseData({
                type: response?.response.type,
                message: response?.response.message,
            });

            if (response.response.validationErros) {
                const errors = responseErrors(
                    response.response.validationErros,
                    errorKeys,
                );

                setCardErrors(errors as any);
                return;
            }
            return;
        }
        navigate(routes.auth.recoveryPassword.formTypeNewPasswordSuccess);
    };
    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Box mb={rem(20)}>
                    <Typography
                        variant="body2"
                        component='h1'
                        align='left'
                        fontSize={rem(22)}
                        color='darkGray'
                        whiteSpace='nowrap'
                        fontWeight={typography.fontWeightBold}>
                        Recuperar senha
                    </Typography>
                </Box>
                <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3} mb={rem(5)}>
                        <TextField
                            focused={true}
                            label='Digite sua nova senha'
                            type={showPassword ? 'text' : 'password'}
                            id="type-new-pwd"
                            helperText={(cardErrors.
                                hasOwnProperty(
                                    'password') &&
                                cardErrors.password.message)}
                            variant="outlined"
                            error={!!errors.password}
                            {...register('password',
                                { required: true },
                            )}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setShowPassword(!showPassword)}>
                                            <I
                                                icon={['fas', showPassword ?
                                                    'eye-slash' :
                                                    'eye',
                                                ]}
                                                fontSize={16}
                                                color={palette.dark}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                        <TextField
                            focused={true}
                            label='Repita sua nova senha'
                            type={showPassword ? 'text' : 'password'}
                            id="retype-pwd"
                            helperText={!!errors.confirmPassword &&
                                errors.confirmPassword.message}
                            variant="outlined"
                            error={!!errors.confirmPassword}
                            {...register('confirmPassword', {
                                required: true,
                                validate: (val: string) => {
                                    if (watch('password') != val) {
                                        return 'As senhas não são iguais';
                                    }
                                },
                            })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setShowPassword(!showPassword)}>
                                            <I
                                                icon={['fas', showPassword ?
                                                    'eye-slash' :
                                                    'eye',
                                                ]}
                                                fontSize={16}
                                                color={palette.dark}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={2}
                        justifyContent='space-between'
                        mt={rem(15)}>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='large'
                            disabled={isLoading.form}>
                            Enviar
                            {isLoading.form &&
                                <CircularProgress
                                    color="inherit"
                                    size={14}
                                    sx={{ ml: 1 }}
                                />}
                        </Button>
                        <ButtonTextArrow
                            text='Fazer login'
                            onClick={() => navigate(routes.auth.login)} />
                    </Stack>
                </Box>
            </Container>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { RecoveryForm };
