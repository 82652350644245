import React from 'react';
import { GlobalContext } from '../../contexts/global/context';
import Cookies from 'universal-cookie';
import { CookieResponseProps } from '@interfaces/Contexts';
import { StoreType } from '@types';

export const globalProvider = () => {
    const cookies = new Cookies(null, { path: '/' });
    const storeTypeStorage = localStorage.getItem('storeType') as StoreType;
    let storeType: string = storeTypeStorage ? storeTypeStorage : 'SHOPPING';

    const [listStoreType,
        setListStoreType,
    ] = React.useState(() => {
        if (!storeTypeStorage) {
            storeType = 'SHOPPING';
        }
        return {
            isLoaded: false,
            type: storeType as StoreType,
        };
    });

    const handleViewType = (type: StoreType) => {
        localStorage.setItem('storeType', type);
        setListStoreType((prev) => prev = {
            isLoaded: false,
            type: type,
        });

        setTimeout(() => {
            setListStoreType((prev) => prev = {
                ...prev,
                isLoaded: true,
            });
        }, 2000);
    };

    const getCookie = async (key: string) => {
        let response = {} as CookieResponseProps;
        try {
            // if (!key) throw new Error('Insira a key do cookie');
            const getJwtCookie = cookies.get(key);
            if (!getJwtCookie) throw new Error('Insira a key do cookie');

            return response = {
                error: false,
                data: { jwt: getJwtCookie },
            };
        } catch (error) {
            return response = {
                error: true,
                data: {
                    jwt: '',
                },
            };
        } finally {
            return response;
        }
    };

    const setCookie = (key: string, token: string) => {
        cookies.set(key, token);
    };

    return {
        app: {
            view: {
                application: listStoreType,
                set: handleViewType,
            },
            cookies: {
                get: getCookie,
                set: setCookie,
            },
        },
    };
};

export const useGlobal = () => {
    const context = React.useContext(GlobalContext);
    return context;
};
