import { FormatPhoneNumberProps } from '@interfaces/Utils';

export const formatNumber = ({
    ddd, number, ddi,
}: FormatPhoneNumberProps) => {
    // eslint-disable-next-line max-len
    return `${ddi ? '+'+ddi+' ' : ''}(${ddd ? ddd : ''}) ${number.substring(0, 5)}-${number.substring(5)}`;
};

export function validatePhoneNumber(phoneNumber: string) {
    const clearNumber = phoneNumber.replace(/\D/g, '');
    const regex = /^([1-9]{2})9[0-9]{8}$/;
    return regex.test(clearNumber);
}
