/* eslint-disable max-len */
import { client } from '@api';
import {
    AuthenticatedPromiseAllProps,
    CreateUserProps,
    CreaterUserCodeValidationProps,
    CreaterUserSendCodeValidationProps,
    JwtTokenProps,
    UpdateUserServiceProps,
    UserUploadImageProps,
} from '@interfaces/Services';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';
import { servicesUtils } from '@utils';

export const user = () => {
    const { services } = client();
    const [userData, setUserData] = React.useState(DEFAULTS.USER.LIST);

    const create = async ({ ...data }: CreateUserProps) => {
        return await services.user.create({ ...data });
    };

    const update = async ({ data, jwt }: UpdateUserServiceProps) => {
        return await services.user.update({ data, jwt });
    };

    const load = async ({ jwt }: JwtTokenProps) => {
        setUserData(DEFAULTS.USER.LIST);
        if (!jwt) {
            setUserData((prev) => prev = {
                ...prev,
                status: servicesUtils.noValidToken.response.status,
                message: servicesUtils.noValidToken.response.message,
                isLoaded: true,
            });
            return servicesUtils.noValidToken;
        }
        const response = await services.user.load({ jwt });
        const balance = await services.user.loadBalance({ jwt });
        const obj = {
            ...response?.response.payload,
            availableBalance: !balance?.error ?
                balance?.response.payload.availableBalance : 0,
        };

        setUserData((prev) => prev = {
            data: response?.response.payload ? obj : {},
            status: response?.response.status,
            isLoaded: true, // !!(!response?.error),
            message: response?.response.message,
        });
        return response;
    };

    const updateUserImages = async ({ type, data }: UserUploadImageProps) => {
        const { services } = client();
        const response = await services.user.upload.images({ type, data });
        return response;
    };

    const sendValidationCode = async (
        { ...data }: CreaterUserSendCodeValidationProps,
    ) => {
        return await services.user.sendValidationCode({ ...data });
    };

    const codeValidation = async (
        { ...data }: CreaterUserCodeValidationProps,
    ) => {
        const response = await services.user.codeValidation({ ...data });
        return response;
    };

    const testFailCart = async () => {
        const errorTest = false;
        let response = {} as AuthenticatedPromiseAllProps;
        try {
            if (errorTest) {
                throw new Error('load cart error');
            }
            return response = {
                error: false,
                response: {
                    message: 'cart is loaded!',
                },
            };
        } catch (error: any) {
            return response = {
                error: true,
                response: {
                    message: error.message,
                },
            };
        } finally {
            return response;
        }
    };

    const testFailFavorites = async () => {
        const errorTest = false;
        let response = {} as AuthenticatedPromiseAllProps;
        try {
            if (errorTest) {
                throw new Error('load favorites error');
            }
            return response = {
                error: false,
                response: {
                    message: 'favorites is loaded!',
                },
            };
        } catch (error: any) {
            return response = {
                error: true,
                response: {
                    message: error.message,
                },
            };
        } finally {
            return response;
        }
    };

    const loadAll = async () => {
        const response = [] as AuthenticatedPromiseAllProps[];
        const promise = Promise.all(
            [
                await testFailCart(),
                await testFailFavorites(),
            ],
        ).then((res) => res);
        (await promise).every((item) => !item?.error === true);

        const data = (await promise)
            .map((item) => item) as AuthenticatedPromiseAllProps[];
        return response.concat(data);
    };

    return {
        load,
        loadAll,
        info: userData,
        upload: {
            images: updateUserImages,
        },
        update,
        account: {
            create,
            auth: {
                validationCode: {
                    send: sendValidationCode,
                    validate: codeValidation,
                },
            },
        },
    };
};

