import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StoreType } from '@types';

export const translateStoreTypeMenu = {
    ['SHOPPING' as StoreType]: 'Ganhar Pontos',
    ['REDEMPTION' as StoreType]: 'Usar Pontos',
};

export const menuHeader = [
    {
        id: '33bed0896dc9',
        title: 'Minha Conta',
        href: '/minha-conta',
        icon: ['far', 'user-circle'] as IconProp,
    },
    {
        id: 'a696edd792ac',
        title: 'Meus Pedidos',
        href: '/meus-pedidos?tab=0',
        icon: ['far', 'boxes-stacked'] as IconProp,
    },
    {
        id: '7b097406ea3c',
        title: 'Meu Extrato',
        href: '/meu-extrato',
        icon: ['far', 'square-list'] as IconProp,
    },
];

export const main = [
    {
        id: '33bed0896dc9',
        title: 'Minha Conta',
        href: '/minha-conta',
        icon: ['far', 'user-circle'] as IconProp,
    },
    {
        id: 'a696edd792ac',
        title: 'Meus Pedidos',
        href: '/meus-pedidos?tab=0',
        icon: ['far', 'boxes-stacked'] as IconProp,
    },
    {
        id: '1db62903cb8b',
        title: 'Meus Endereços',
        href: '/meus-enderecos',
        icon: ['far', 'map-location-dot'] as IconProp,
    },
    {
        id: '7b097406ea3c',
        title: 'Meu Extrato',
        href: '/meu-extrato',
        icon: ['far', 'square-list'] as IconProp,
    },
    {
        id: '4b097406e44d',
        title: 'Meus Créditos',
        href: '/meus-creditos',
        icon: ['far', 'wallet'] as IconProp,
    },
    {
        id: '1db62903er5a',
        title: 'Formas de pagamento',
        icon: ['far', 'credit-card-blank'] as IconProp,
        href: '/formas-de-pagamento',
    },
];

export const menu = (excludedHashes: string[]) => {
    return [
        {
            id: 'e8c629d86f9c',
            title: 'Parceiros',
            hash: 'partners',
            href: '/menu/parceiros',
            icon: ['far', 'layer-group'] as IconProp,
        },
        {
            id: '279bd9720247',
            title: translateStoreTypeMenu['SHOPPING'],
            hash: 'storeType',
            href: '/escolher',
            icon: ['far', 'arrow-up-arrow-down'] as IconProp,
        },
        {
            id: 'f4ce7ca8d067',
            title: 'Categorias',
            hash: 'categories',
            href: '/menu/categorias',
            icon: ['far', 'list-tree'] as IconProp,
        },
        {
            id: 'e0df6b6d9a43',
            title: 'Filtros',
            hash: 'filters',
            icon: ['far', 'sliders'] as IconProp,
        },
    ].filter((item) => !excludedHashes.includes(item.hash));
};

export const usermenu = [
    // {
    //     id: '1db62903cb8b',
    //     title: 'Meus Endereços',
    //     href: '/meus-enderecos',
    //     hash: 'helper',
    //     icon: ['far', 'map-location-dot'] as IconProp,
    // },
    // {
    //     id: '1db62903er5a',
    //     title: 'Formas de pagamento',
    //     icon: ['far', 'credit-card-blank'] as IconProp,
    //     hash: 'helper',
    //     href: '/formas-de-pagamento',
    // },
    {
        id: 'ea4b5f65ff34',
        title: 'Sair',
        href: '#!',
        hash: 'logout',
        action: 'logout',
        icon: ['far', 'right-to-bracket'] as IconProp,
    },
    {
        id: '2ca51802ba9a',
        title: 'Central de ajuda',
        href: '/central-de-ajuda',
        hash: 'helper',
        icon: ['far', 'question-circle'] as IconProp,
    },
];

export const categories = [
    {
        id: '69121b8de98b',
        title: 'Celulares e Smartphones',
    },
    {
        id: '527f4b2f460b',
        title: 'Agro, Indústria e Comércio',
    },
    {
        id: '39c81705c94c',
        title: 'Áudio',
    },
    {
        id: 'bfd13c70478e',
        title: 'Automotivo',
    },
    {
        id: '53aa9dd80df7',
        title: 'Bebês',
    },
    {
        id: 'ecd679700f3b',
        title: 'Bebidas',
    },
    {
        id: 'b96558bf2378',
        title: 'Brinquedos',
    },
];

export const filterPageOptions = [
    {
        _id: '5833c4404634',
        name: 'Preço crescente',
        value: '1',
        filterType: 'orderBy',
    },
    {
        _id: '26a1c3f15134',
        name: 'Preço decrescente',
        value: '-1',
        filterType: 'orderBy',
    },
];
