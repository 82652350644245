/* eslint-disable max-len */
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { rem, styles } from '@utils';
import { sx } from './sx';
import React from 'react';
import { ButtonTextArrow } from '../../components/text-arrow-button';
import { useNavigate } from 'react-router-dom';
import { RecoveryPasswordProps } from '@interfaces/Services';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from '@hooks/auth';
import { DEFAULTS } from './DEFAULTS';
import { Feedback } from '@components';
import { routes } from '@static/routes.list';

interface RecoveryPasswordTypeEmailForm extends RecoveryPasswordProps { }

const Form: React.FC = () => {
    const { typography } = useTheme();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    const navigate = useNavigate();

    const textAlign = mediaQuery.md ? 'center' : 'left';
    const auth = useAuth();

    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RecoveryPasswordTypeEmailForm>({
        mode: 'onSubmit',
    });
    const handleClose = () => setOpen(false);

    const onSubmit: SubmitHandler<RecoveryPasswordTypeEmailForm> = async (data) => {
        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });
        const response = await auth.recoveryPassword.send({
            email: data.email,
            firstAccess: true,
        });

        if (response?.error) {
            setIsLoading(DEFAULTS.loading);
            setOpen(true);
            setResponseData({
                type: response?.response.type,
                message: response?.response.message,
            });
            return;
        }
        navigate(routes.auth.recoveryPassword.formTypeEmailSuccess);
    };

    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Box>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(22)}
                        color='darkGray'
                        fontWeight={typography.fontWeightBold}
                        gutterBottom>
                        Recuperar senha
                    </Typography>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(14)}
                        color='darkGray'
                        fontWeight={typography.fontWeightMedium}>
                        Digite o e-mail cadastrado para que possamos
                        encontrar sua conta.
                    </Typography>
                    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} mb={rem(20)} mt={rem(30)}>
                            <TextField
                                fullWidth
                                focused={true}
                                label={'E-Mail'}
                                id="email"
                                helperText=''
                                variant="outlined"
                                error={!!errors.email}
                                {...register('email',
                                    { required: true },
                                )} />
                        </Stack>
                        <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='space-between'
                            mt={rem(15)}>
                            <Button
                                fullWidth
                                type='submit'
                                variant='contained'
                                size='large'
                                disabled={isLoading.form}>
                                Enviar
                                {isLoading.form &&
                                <CircularProgress
                                    color="inherit"
                                    size={14}
                                    sx={{ ml: 1 }}
                                />}
                            </Button>
                            <ButtonTextArrow
                                text='Fazer login'
                                onClick={() => navigate(routes.auth.login)} />
                        </Stack>
                    </Box>
                </Box>
            </Container>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { Form };
