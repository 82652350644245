/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Box, Grid } from '@mui/material';
import { Image } from '../../../atoms/image';
import { sx } from './sx';
import { LinkOverlay } from './styles';
import { useAds } from '@hooks/ads';
import React from 'react';
import { files, numbers } from '@utils';
import { useNavigate } from 'react-router-dom';
import { FILTERS_KEYS } from '@static/filters';
import { CONSTANTS } from '@static/constants';
import { useFilters } from '@hooks/filters';
import { MessageBox } from '@components';

const Partners: React.FC = () => {
    const { css } = sx();
    const ad = useAds();
    const navigate = useNavigate();
    const { clearFilters } = useFilters();
    const loadPartnersStores = async () => {
        await ad.stores.load();
    };

    const resetFilters = () => {
        clearFilters();
        if (ad.categories.list.history.length >= 1) {
            ad.categories.reset({ type: 'history' });
            ad.categories.reset({ type: 'categories' });
        }
    };

    const goTo = (storeURL: string) => {
        const params = {
            limit: FILTERS_KEYS.LIMIT + '=' + CONSTANTS.SEARCH.LIMIT,
            aleatory: FILTERS_KEYS.ALEATORY + '=' + numbers.randomNumber({ maxRange: 100 }),
        };
        resetFilters();
        navigate({
            pathname: '/resultados/busca/',
            search: `storeUrl=${storeURL}&${params.limit}&${params.aleatory}`,
        });
    };

    React.useEffect(() => {
        if (!ad.stores.list.isLoaded) {
            loadPartnersStores();
        }
    }, []);

    return (
        <Box maxWidth={'424px'}>
            <Grid container spacing={1}>
                {ad.stores.list.isLoaded && (ad.stores.list.data.length > 0 ?
                    ad.stores.list.data.map((store, i) => (
                        <Grid item xs={4} key={`${store.url + i + 1}`}>
                            <Box sx={css.wrapper} onClick={() => goTo(store.url)}>
                                <Image
                                    src={store.images?.avatar ?
                                        files.load(store.images.avatar, 'server') :
                                        ''}
                                    alt={store.title}
                                    maxHeight={48}
                                    objectFit='cover'
                                />
                            </Box>
                        </Grid>
                    )) :
                    <Grid item xs={12}>
                        <Box p={2}>
                            <MessageBox
                                message={'Não temos parceiros cadastrados no momento'}
                                type={'info'} />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export { Partners };
