/* eslint-disable max-len */
import React from 'react';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TextField,
    useTheme,
} from '@mui/material';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';
import { Feedback, HeaderPage, TableList } from '@components';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from '@hooks/user';
import { DEFAULTS } from './DEFAULTS';
import { useAuth } from '@hooks/auth';
import { useGlobal } from '@hooks/global';

export interface UpdateUserProfilePasswordForm {
    password: string;
    newPassword: string
    confirmNewPassword: string
}

const EditPassword: React.FC = () => {
    const { css } = sx();
    const { palette } = useTheme();

    const user = useUser();
    const auth = useAuth();
    const global = useGlobal();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<UpdateUserProfilePasswordForm>({
        mode: 'onSubmit',
    });

    const handleClose = () => setOpen(false);

    const logoutUser = async () => {
        const response = await auth.logout({ user });
        if (!response?.error) {
            navigate('/auth/login');
            window.location.reload();
        }
    };

    const onSubmit: SubmitHandler<UpdateUserProfilePasswordForm> = async (data) => {
        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });
        const cookieSession = await global.app.cookies.get('session');
        const response = await user.update({
            data: {
                password: data.password,
                newPassword: data.confirmNewPassword,
            },
            jwt: cookieSession.data.jwt,
        });

        setIsLoading(DEFAULTS.loading);
        setResponseData({
            type: response?.response.type,
            message: response?.response.message,
        });
        setOpen(true);
        if (!response?.error) {
            await logoutUser();
        }
    };

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <HeaderPage
                onClick={() => navigate(-1)}
                icon={['far', 'chevron-left']}
                titleAlign={'default'}
                title={'Alterar Senha'} />
            <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                <Table>
                    <TableBody>
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Senha atual'}
                            value={
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    sx={{ ...css.textfield }}
                                    fullWidth
                                    focused={true}
                                    id="old-pass"
                                    helperText=''
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassword(!showPassword)}>
                                                    <I
                                                        icon={['fas', showPassword ?
                                                            'eye-slash' :
                                                            'eye',
                                                        ]}
                                                        fontSize={16}
                                                        color={palette.dark}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={!!errors.password}
                                    {...register('password',
                                        { required: true },
                                    )} />
                            } />
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Nova senha'}
                            value={
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    sx={{ ...css.textfield }}
                                    fullWidth
                                    focused={true}
                                    id="new-pass"
                                    helperText=''
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassword(!showPassword)}>
                                                    <I
                                                        icon={['fas', showPassword ?
                                                            'eye-slash' :
                                                            'eye',
                                                        ]}
                                                        fontSize={16}
                                                        color={palette.dark}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={!!errors.newPassword}
                                    {...register('newPassword',
                                        { required: true },
                                    )} />
                            } />
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Repita a senha'}
                            value={
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    sx={{ ...css.textfield }}
                                    fullWidth
                                    focused={true}
                                    id="retype-new-pass"
                                    variant="outlined"
                                    helperText={!!errors.confirmNewPassword &&
                                        errors.confirmNewPassword.message}
                                    error={!!errors.confirmNewPassword}
                                    {...register('confirmNewPassword', {
                                        required: true,
                                        validate: (val: string) => {
                                            if (watch('newPassword') != val) {
                                                return 'As senhas não são iguais';
                                            }
                                        },
                                    })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassword(!showPassword)}>
                                                    <I
                                                        icon={['fas', showPassword ?
                                                            'eye-slash' :
                                                            'eye',
                                                        ]}
                                                        fontSize={16}
                                                        color={palette.dark}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                            } />
                    </TableBody>
                </Table>
                <Box sx={{ ...css.box.wrapperButton }}>
                    <Box sx={{ ...css.box.buttonSubmit }}>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='large'
                            disabled={isLoading.form}>
                            Salvar alterações
                            {isLoading.form &&
                                <CircularProgress
                                    color="inherit"
                                    size={14}
                                    sx={{ ml: 1 }}
                                />}
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { EditPassword };
