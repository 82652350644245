import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9,
            backgroundColor: 'rgba(255,255,255,0.4)',
            pt: 2,
            pb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        box: {
            wrapper: {
                border: mediaQuery.lg ?
                    `${rem(1)} solid ${palette.cityLights}` :
                    'none',
            },
            wrapperButton: {
                p: rem(20),
                display: 'flex',
                justifyContent: 'center',
            },
            buttonSubmit: {
                maxWidth: rem(244),
                width: '100%',
            },
            avatarEdit: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        button: {
            changePass: {
                minWidth: rem(80),
                color: palette.mediumVioletRed,
                fontSize: rem(13),
                fontWeight: 700,
                ml: rem(10),
            },
        },
        textfield: {
            '& > .MuiOutlinedInput-root': {
                '& > .MuiInputBase-input': {
                    'padding': `${rem(10)} ${rem(20)}`,
                    'fontSize': `${rem(14)}`,
                },
            },
        },
        customInput: {
            '&>input[type=text]': {
                ...{
                    width: '100%',
                    border: '1px solid ' + palette.clouds,
                    padding: ' 0.625rem 1.25rem',
                    fontSize: '0.875rem',
                    borderRadius: rem(4),
                    outline: 'none',
                },
                '&:focus': {
                    outline: 'none',
                },
                '&.error': {
                    border: '1px solid ' + palette.status.rejected,
                },
            },
        },
        select: {
            '& > .MuiSelect-select': {
                'padding': `${rem(10)} ${rem(20)}`,
                'fontSize': `${rem(14)}`,
            },
        },
        icon: {
            camera: {
                marginLeft: rem(6),
            },
        },
    };

    return { css };
};
