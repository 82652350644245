/* eslint-disable indent */
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { BoxContentProps } from '@interfaces/index';

import { sx } from './sx';
import { LegendButton } from '../../molecule';
import { Link } from 'react-router-dom';

const BoxContent: React.FC<BoxContentProps> = ({
    actions,
    children,
    customAction,
    htmlFor,
    link,
}: BoxContentProps) => {
    const { css } = sx();

    const properties = htmlFor ? { htmlFor: htmlFor } : {};

    return (
        <Box className='box-content'
            sx={{ ...css.sxWrapper }}
            component={htmlFor ? 'label' : 'div'}
            {...properties}>
            {link && <Link style={{ ...css.sxLink }} to={link}></Link>}
            <Box sx={{ ...css.sxBoxChildren, cursor: htmlFor ? 'pointer' : 'default' }}>
                {children}
            </Box>
            <Box sx={{ ...css.sxBoxActions }}>
                {actions && actions.map(({
                    actionType, actionFunction, noLegend, loading, disabled }, i) => {
                    if (loading) {
                        return (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '68px',
                                mx: 2,
                            }}>
                                <CircularProgress color='inherit' size={18} />
                            </Box>
                        );
                    }
                    return (
                        <LegendButton
                            key={`${i}-action`}
                            actionType={actionType}
                            noLegend={noLegend}
                            actionFunction={actionFunction}
                            disabled={disabled}
                        />
                    );
                })}
                {customAction && customAction}
            </Box>
        </Box>
    );
};

export { BoxContent };
