import { client } from '@api';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';
import {
    FavoriteProductProps,
    FavoritesTypeCallProps,
    HandleFavoriteActionProps,
    IsItemFavoritesProps,
} from '@interfaces/Services';
import { servicesUtils } from '@utils';
import { useGlobal } from '@hooks/global';

export const userFavorites = () => {
    const { services } = client();
    const global = useGlobal();
    const [listFavorites, setListFavorites] = React.useState(
        DEFAULTS.USER.FAVORITES.LIST,
    );

    const handle = async ({ ...data }: HandleFavoriteActionProps) => {
        return await services.user.favorites.handle({ ...data });
    };

    const load = async ({ favotireType }: FavoritesTypeCallProps) => {
        setListFavorites(DEFAULTS.USER.FAVORITES.LIST);
        const session = await global.app.cookies.get('session');
        if (session.error) {
            setListFavorites((prev) => prev = {
                ...prev,
                status: servicesUtils.noValidToken.response.status,
                message: servicesUtils.noValidToken.response.message,
                isLoaded: false,
            });
            return servicesUtils.noValidToken;
        }
        const response = await services.user.favorites.load({ favotireType });
        setListFavorites((prev) => prev = {
            message: response?.response.message ?
                response?.response.message :
                '',
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload : DEFAULTS.USER.FAVORITES.LIST.data.favorites,
        });
        return response;
    };

    const favoriteProduct = async ({
        product,
        favorite,
        applicationType,
    }: FavoriteProductProps) => {
        const response = await favorite.handle({
            idAd: product._id,
            idVariation: product.variations._id,
            variationItemId: product.variations.items[0]._id,
            typeAd: applicationType,
        });

        if (!response?.error) {
            favorite.load({ favotireType: applicationType });
        }
        return response;
    };

    const verifyIsFavoriteProduct =
        ({ favorites, variationItemId }: IsItemFavoritesProps) => {
            let i = false;
            if (Object.keys(favorites).length === 0) {
                return i;
            }
            favorites.map((item) => {
                if (item.variation.items._id === variationItemId) {
                    i = true;
                }
                return;
            });

            return i;
        };

    return {
        favorites: {
            load,
            handle,
            favoriteProduct,
            list: listFavorites,
            verify: verifyIsFavoriteProduct,
        },
    };
};
