import { Template } from '@components';
import { Box, Container } from '@mui/material';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';
import { routes } from '@static/routes.list';

const RecoverySuccessFeedback: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();

    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Template.AuthFeedback
                    title='Senha alterada!'
                    subtitle='Clique no botão abaixo para fazer o
                    login com a sua nova senha'
                    button={{
                        text: 'Voltar para o login',
                        onClick: () => navigate(routes.auth.login),
                    }}
                />
            </Container>
        </Box>
    );
};

export { RecoverySuccessFeedback };
