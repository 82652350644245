/* eslint-disable max-len */
import React from 'react';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { rem } from '@utils';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { ButtonTextArrow } from '../components/text-arrow-button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginProps } from '@interfaces/Services';
import { Feedback } from '@components';
import { DEFAULTS } from './DEFAULTS';
import { useUser } from '@hooks/user';
import { useGlobal } from '@hooks/global';
import { StoreType } from '@types';
import { useLoad } from '@hooks/load';
import { routes } from '@static/routes.list';

interface LoginForm extends LoginProps { }

const Login: React.FC = () => {
    const { typography, palette } = useTheme();
    const { servicesLoad } = useLoad();
    const [showPassword, setShowPassword] = React.useState(false);
    const { css } = sx();
    const navigate = useNavigate();
    const auth = useAuth();
    const user = useUser();
    const global = useGlobal();

    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>({
        mode: 'onSubmit',
    });

    const handleClose = () => setOpen(false);

    const onSubmit: SubmitHandler<LoginForm> = async (data) => {
        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });

        const response = await auth.login({
            ...data,
        });

        if (response?.error) {
            setIsLoading(DEFAULTS.loading);
            setOpen(true);
            setResponseData({
                type: response?.response.type,
                message: response?.response.message,
            });
            return;
        }

        const responseUser = await user.load({
            jwt: response?.response.accessToken,
        });

        if (!responseUser?.error) {
            if (!responseUser?.response.payload.validated) {
                setOpen(true);
                setResponseData({
                    type: 'info',
                    message: 'Ative sua conta',
                });
                setTimeout(() => {
                    navigate({
                        pathname: routes.auth.createAccount.sendConfirmationCode,
                        search: `?email=${data.login}`,
                    });
                }, 1000);
                return;
            }
            if (!response?.error) {
                global.app.view.set(
                    localStorage.getItem('storeType') as StoreType || 'SHOPPING',
                );
                global.app.cookies.set('session', response?.response.accessToken);
            }
            await servicesLoad({
                loadList: ['favorites', 'cart'],
                forceLoad: ['favorites', 'cart'],
                application: global.app.view.application,
            });
            navigate('/');
        }
    };

    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Box mb={rem(20)}>
                    <Typography
                        variant="body2"
                        component='h1'
                        align='left'
                        fontSize={rem(22)}
                        color='darkGray'
                        whiteSpace='nowrap'
                        fontWeight={typography.fontWeightBold}>
                        Fazer login
                    </Typography>
                </Box>
                <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3} mb={rem(5)}>
                        <TextField
                            fullWidth
                            focused
                            label='Login'
                            id="outlined-basic"
                            helperText=''
                            variant="outlined"
                            error={!!errors.login}
                            {...register('login',
                                { required: true },
                            )} />
                        <TextField
                            focused
                            label='Senha atual'
                            type={showPassword ? 'text' : 'password'}
                            id="input-with-icon-textfield"
                            helperText={''}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setShowPassword(!showPassword)}>
                                            <I
                                                icon={['fas', showPassword ?
                                                    'eye-slash' :
                                                    'eye',
                                                ]}
                                                fontSize={16}
                                                color={palette.dark}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!!errors.password}
                            {...register('password',
                                { required: true },
                            )} />
                    </Stack>
                    <Button
                        type='button'
                        variant='text'
                        size='small'
                        onClick={() => navigate(routes.auth.recoveryPassword.formTypeEmail)}>
                        <Typography
                            variant="body2"
                            component='h1'
                            align='left'
                            fontSize={rem(14)}
                            color='darkGray'
                            whiteSpace='nowrap'
                            fontWeight={typography.fontWeightSemiBold}>
                            Esqueci minha senha
                        </Typography>
                        <Box ml={rem(5)}>
                            <I
                                icon={['fas', 'chevron-right']}
                                fontSize={rem(11)}
                                color={palette.dark} />
                        </Box>
                    </Button>
                    <Stack
                        direction='row'
                        spacing={2}
                        justifyContent='space-between'
                        mt={rem(15)}>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='large'>
                            Entrar
                            {isLoading.form &&
                                <CircularProgress
                                    color="inherit"
                                    size={14}
                                    sx={{ ml: 1 }}
                                />}
                        </Button>
                        <ButtonTextArrow
                            text='Criar conta'
                            onClick={() => navigate(routes.auth.createAccount.form)}
                        />
                    </Stack>
                </Box>
            </Container>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { Login };
