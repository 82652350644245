/* eslint-disable max-len */
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { rem, styles } from '@utils';
import { sx } from './sx';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import 'jquery-mask-plugin';
import { useUser } from '@hooks/user';
import { Feedback } from '@components';
import { DEFAULTS } from './DEFAULTS';
import { routes } from '@static/routes.list';


const SendConfirmationCode: React.FC = () => {
    const { typography } = useTheme();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const user = useUser();

    const navigate = useNavigate();
    const textAlign = mediaQuery.md ? 'center' : 'left';

    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });
    const [value, setValue] = React.useState('email');
    const [searchParams] = useSearchParams();
    const emailParam = searchParams.get('email');
    const phoneParam = searchParams.get('phone');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const onSubmit = async () => {
        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });

        const email = !!(value === 'email');
        const sms = !!(value === 'sms');
        const response = await user.account.auth.validationCode.send({
            sendEmail: email,
            sendSms: sms,
            email: emailParam ?? undefined,
        });
        setIsLoading(DEFAULTS.loading);
        setOpen(true);
        setResponseData({
            type: response?.response.type,
            message: response?.response.message,
        });

        if (!response?.error) {
            setTimeout(() => {
                navigate({
                    pathname: routes.auth.createAccount.typeConfirmationCode + value,
                    search: `?${value}=${emailParam || phoneParam}`,
                });
            }, 1500);
        }
    };

    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Box>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(22)}
                        color='darkGray'
                        fontWeight={typography.fontWeightBold}
                        gutterBottom>
                        Enviar código de confirmação
                    </Typography>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(14)}
                        color='darkGray'
                        fontWeight={typography.fontWeightMedium}>
                        Escolha por onde deseja receber o código de
                        confirmação da sua conta
                    </Typography>
                    <Box component='form' mt={rem(20)}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <Stack spacing={1} mb={rem(15)}>
                                    <FormControlLabel
                                        value="email"
                                        control={<Radio size='small' />}
                                        label={
                                            <Box ml={1}>
                                                <Typography
                                                    variant="body1"
                                                    component='h1'
                                                    align="left"
                                                    color='darkGray'
                                                    fontWeight={typography.fontWeightBold}>
                                                    E-Mail
                                                </Typography>
                                                {emailParam && (
                                                    <Typography
                                                        variant="body2"
                                                        component='p'
                                                        align="left"
                                                        color='darkGray'
                                                        fontWeight={typography.fontWeightMedium}>
                                                        {emailParam}
                                                    </Typography>
                                                )}
                                            </Box>
                                        }
                                    />
                                    {!emailParam &&
                                        <FormControlLabel
                                            value="sms"
                                            control={<Radio size='small' />}
                                            label={
                                                <Box ml={1}>
                                                    <Typography
                                                        variant="body1"
                                                        component='h1'
                                                        align="left"
                                                        color='darkGray'
                                                        fontWeight={typography.fontWeightBold}>
                                                        SMS
                                                    </Typography>
                                                    {phoneParam && (
                                                        <Typography
                                                            variant="body2"
                                                            component='p'
                                                            align="left"
                                                            color='darkGray'
                                                            fontWeight={typography.fontWeightMedium}>
                                                            {phoneParam}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            }
                                        />
                                    }
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='space-between'
                            mt={rem(15)}>
                            <Button
                                fullWidth
                                type='button'
                                variant='contained'
                                size='large'
                                disabled={isLoading.form}
                                onClick={onSubmit}
                            >
                                Enviar
                                {isLoading.form &&
                                    <CircularProgress
                                        color="inherit"
                                        size={14}
                                        sx={{ ml: 1 }}
                                    />}
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Container>

            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { SendConfirmationCode };
