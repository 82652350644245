/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
import React from 'react';
import { ButtonSelectedFilter, Filters, MessageBox, Product, ProductSkeleton, Image } from '@components';
import { Box, Button, CircularProgress, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { files, rem, styles } from '@utils';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import { sx } from './sx';
import { filtersList, orderByFilterTranslate, translateFilters } from '@static/filters';
import { useFilters } from '@hooks/filters';
import { useLocation, useParams, useSearchParams, ScrollRestoration } from 'react-router-dom';
import queryString from 'query-string';
import { FiltersParamsKeysType, FiltersType, FiltersTypeProps } from '@types';
import { useAds } from '@hooks/ads';
import { CONSTANTS } from '@static/constants';
import { ResultsFiltersList } from '../../../filters';
import { useGlobal } from '@hooks/global';
import { useUser } from '@hooks/user';
import { useLayout } from '@hooks/layout';
const IS_LOADING = {
    SKELETON: false,
    SHOW_MORE: false,
};

const Search: React.FC = () => {
    const refEl = React.useRef<HTMLDivElement>(null);
    const matches = useMediaQuery('(min-width:600px)');
    const { typography, palette } = useTheme();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const popupState = usePopupState({ variant: 'popper', popupId: 'filterOptions' });
    const urlParams = useParams();
    const location = useLocation();
    const ad = useAds();
    const global = useGlobal();
    const user = useUser();
    const layout = useLayout();
    const applicationType = global.app.view.application.type;
    const [search, setSearch] = useSearchParams();
    const [isLoading, setIsLoading] = React.useState(IS_LOADING);
    const [tempOrderBy, setTempOrderBy] = React.useState('Ordenar por');

    const {
        filters,
        listActiveFilters,
        serviceSearchQuery,
        removeFilter,
        handleURLQuery,
        onChangeFilters,
    } = useFilters();

    const params = {
        key: urlParams.key as string,
    } as FiltersParamsKeysType;

    const scrollToElement = () => {
        const lastChildElement = refEl.current;
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    };

    const changeLimitParam = () => {
        setIsLoading({
            ...IS_LOADING,
            SHOW_MORE: true,
        });
        const limitParam = Number(search.get('limit'));

        const limit = limitParam + CONSTANTS.SEARCH.LIMIT_ON_SCROLL;

        search.set('limit', limit.toString());
        setSearch(search);
    };

    const orderByObjKeys = Object.keys(orderByFilterTranslate);
    const onChangeOrderByFilter = (type: FiltersType) => {
        const findFilter = filters[type].find((filter) => orderByObjKeys.includes(filter._id));
        if (orderByFilterTranslate[findFilter?._id as any]) {
            setTempOrderBy(orderByFilterTranslate[findFilter?._id as any]);
        } else {
            setTempOrderBy('Ordenar por');
        }
    };

    const setURLParams = async (queryStringParams: string) => {
        setIsLoading({
            ...IS_LOADING,
            SKELETON: true,
        });
        const parse = queryString.parse(queryStringParams, {
            arrayFormat: 'index',
        }) as FiltersTypeProps;

        const response = await serviceSearchQuery({
            paramsList: params,
            queryStringParams: queryStringParams,
        });

        if (!response.error) {
            const listings = await ad.ads.search.find({
                query: response.data,
                viewType: applicationType,
            });
            if (!listings.error) {
                const objectList = await handleURLQuery(parse);
                if (!objectList.error) {
                    onChangeFilters(parse);
                    setIsLoading(IS_LOADING);
                    if (!(ad.categories.list.categories.data.length > 0)) {
                        ad.categories.handleCategorization({});
                    }
                }
            }
        }
    };

    const remove = (filterKey: FiltersType) => {
        search.delete(filterKey);
        setSearch(search);
    };

    const clearFilter = async (type: FiltersType) => {
        const response = await removeFilter(type);
        if (!response.error) {
            handleURLQuery(response.data);
            remove(type);
            if (type === 'categoryList') {
                // await ad.categories.listCategorization({});
                ad.categories.reset({ type: 'categories' });
                ad.categories.reset({ type: 'history' });
            }
        }
    };

    React.useEffect(() => {
        setTimeout(async () => {
            await setURLParams(location.search);
        }, 500);
    }, [location.search, applicationType]);

    React.useEffect(() => {
        // setTempOrderBy(filters.orderBy);
        if (filters.orderBy.length > 0) {
            onChangeOrderByFilter('orderBy');
        }
    }, [filters.orderBy]);

    React.useEffect(() => {
        if (!isLoading.SHOW_MORE) {
            setTimeout(() => {
                scrollToElement();
            }, 1500);
        }
    }, [isLoading.SHOW_MORE]);

    return (
        <>
            <ScrollRestoration getKey={(location) => location.pathname} />
            <Box sx={{ ...css.box.wrapper }}>
                <Box mt={rem(mediaQuery.md ? 10 : 0)} height='100%'>
                    <Container maxWidth='lg' sx={{ height: '100%' }}>
                        <Box display='flex' alignItems='stretch' height='100%'>
                            {mediaQuery.md && (
                                <Box sx={css.box.sidebar.wrapper}>
                                    <Filters.FilterHeader
                                        title='Filtros'
                                        bgColor={palette.common.white}
                                    />
                                    <Box sx={{ ...css.box.sidebar.filters }}>
                                        <Filters.Search filtersList={filtersList} />
                                    </Box>
                                </Box>
                            )}
                            <Box overflow='hidden' sx={{
                                ml: mediaQuery.md ? rem(15) : 0,
                                width: '100%',
                            }}>
                                {!!params.key && params.key !== 'undefined' ? (
                                    <Box pt={rem(15)} pb={rem(5)}>
                                        <Typography
                                            color="darkGray"
                                            fontSize={rem(18)}
                                            fontWeight={typography.fontWeightBold}
                                            sx={{ ...css.texts.results }}
                                        >
                                            Resultados para: <Typography
                                                color="darkGray"
                                                fontSize={rem(18)}
                                                fontWeight={typography.fontWeightMedium}
                                                component='span'
                                                textTransform='capitalize'
                                            >
                                                {params.key}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                ) : ''}
                                {listActiveFilters.isLoaded ||
                                    ad.ads.search.list.data.store.isLoaded ? (
                                    <Box>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Typography
                                                color="darkGray"
                                                fontSize={rem(12)}
                                                fontWeight={typography.fontWeightSemiBold}
                                                sx={{ ...css.texts.filters }}
                                            >
                                                Filtros <span>Ativos:</span>
                                            </Typography>
                                            <Stack direction='row' alignItems='center' spacing={1} sx={{
                                                ...css.stack.selectedFilters,
                                            }}>
                                                {listActiveFilters.isLoaded &&
                                                    listActiveFilters.data.map((item, i) => {
                                                        if (['showcaseId', 'quickLinkId'].includes(item.filterType)) return;
                                                        return (
                                                            <ButtonSelectedFilter
                                                                key={`${item.filterType}-${i}`}
                                                                title={translateFilters[item.filterType as FiltersType].title}
                                                                value={item.list.map((filter, i, arr) => {
                                                                    const comma = i + 1 !== arr.length ? ' · ' : '';
                                                                    return filter.name + comma;
                                                                })}
                                                                onClick={() => clearFilter(item.filterType)}
                                                            />
                                                        );
                                                    })}
                                                {ad.ads.search.list.data.store.isLoaded && (
                                                    <ButtonSelectedFilter
                                                        title={translateFilters['storeUrl'].title}
                                                        value={
                                                            <Image
                                                                src={files.load(ad.ads.search.list.data.store.images.avatar, 'server')}
                                                                alt={ad.ads.search.list.data.store.title}
                                                                maxHeight={28}
                                                                objectFit='cover'
                                                            />
                                                        }
                                                        onClick={() => clearFilter('storeUrl')}
                                                    />
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                ) : ''}

                                {ad.ads.search.list.isLoaded &&
                                    (ad.ads.search.list.data.ads.length > 0) && (
                                        <Box mt={rem(15)}>
                                            <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                                                {/* <Typography
                                                color="darkGray"
                                                fontSize={rem(13)}
                                                fontWeight={typography.fontWeightSemiBold}
                                            >
                                                Encontramos 1024 produto(s)
                                            </Typography> */}

                                                <Button
                                                    variant='outlined'
                                                    sx={{ ...css.button.relevants }}
                                                    {...bindHover({
                                                        ...popupState,
                                                    })}>
                                                    <Stack
                                                        direction='row'
                                                        alignItems='center'
                                                        justifyContent='space-between'
                                                        spacing={1}>
                                                        <Typography
                                                            color="mediumVioletRed"
                                                            fontSize={rem(12)}
                                                            fontWeight={typography.fontWeightMedium}
                                                        >
                                                            {tempOrderBy}
                                                        </Typography>
                                                        <I
                                                            icon={['fas', 'chevron-down']}
                                                            fontSize={rem(12)} />
                                                    </Stack>
                                                </Button>
                                            </Stack>
                                        </Box>
                                    )}
                                <Box mt={rem(15)}>
                                    <Grid container spacing={2}>
                                        {(!layout.layout.loading.value && ad.ads.search.list.isLoaded) ? (
                                            ad.ads.search.list.data.ads.length > 0 ?
                                                ad.ads.search.list.data.ads.map((product, i, list) => (
                                                    <Grid item md={4} sm={4} xs={6}>
                                                        <Product
                                                            product={product}
                                                            favorite={{
                                                                isFavorite: user.favorites.verify({
                                                                        favorites: user.favorites.list?.data?.favorites,
                                                                        variationItemId: product.variations.items[0]._id,
                                                                    }),
                                                            }}
                                                            cart={{
                                                                isInCart: user.cart.isItemInCart({
                                                                    cart: user.cart.list.data,
                                                                    variationItemId: product.variations.items[0]._id,
                                                                }),
                                                            }}
                                                            actions={{
                                                                handleFavorite: async () => await user.favorites.favoriteProduct({
                                                                    product,
                                                                    applicationType,
                                                                    favorite: user.favorites,
                                                                }),
                                                                handleCart: async () => await user.cart.handleItem.boxProduct({
                                                                    cart: user.cart,
                                                                    product,
                                                                }),
                                                            }} />
                                                        <div ref={
                                                            (matches ?
                                                                (i === list.length - 8) :
                                                                (i === list.length - 8)) ?
                                                                refEl :
                                                                null
                                                        }
                                                            className={
                                                                (matches ?
                                                                    (i === list.length - 8) :
                                                                    (i === list.length - 8)) ?
                                                                    'refEl' :
                                                                    ''
                                                            }
                                                        />
                                                    </Grid>

                                                )) :
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <MessageBox
                                                        message='Não foi possível encontrar anúncios'
                                                        type='info'
                                                    />
                                                </Grid>
                                        ) : null}
                                        {(isLoading.SKELETON || layout.layout.loading.value) &&
                                            Array.from(Array(6).keys()).map((_, i) => (
                                                <Grid key={'ads-list-ad-' + i} item md={4} sm={4} xs={6}>
                                                    <ProductSkeleton />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Box>

                                {ad.ads.search.list.isLoaded && ad.ads.search.list.data.ads.length > 0 && (
                                    <Stack direction='row' justifyContent='center' mt={rem(20)}>
                                        <Button
                                            type='button'
                                            variant='contained'
                                            size='large'
                                            disabled={Number(search.get('limit')) > ad.ads.search.list.data.ads.length}
                                            onClick={() => changeLimitParam()}>
                                            Carregar mais
                                            {isLoading.SHOW_MORE &&
                                                <CircularProgress
                                                    color="inherit"
                                                    size={14}
                                                    sx={{ ml: 1 }}
                                                />}
                                        </Button>
                                    </Stack>
                                )}
                            </Box>
                        </Box>
                    </Container>

                    <HoverPopover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box>
                            <ResultsFiltersList.OrderBy onClick={() => popupState.close()} />
                        </Box>
                    </HoverPopover>
                </Box >
                <ScrollRestoration />
            </Box>
        </>
    );
};

export { Search };
