/* eslint-disable max-len */
import { ButtonSelectedFilter, Display, Image, MenuItem as MenuItemComponent } from '@components';
import { Box, Button, Container, Divider, Grid, MenuItem, MenuList, Paper, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { categories, filterPageOptions } from '@static/menus';
import { rem, styles } from '@utils';
import { slickData } from '@static/slick-data';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import { sx } from './sx';
import React from 'react';

const Partner: React.FC = () => {
    const { typography } = useTheme();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    const popupState = usePopupState({ variant: 'popper', popupId: 'filterOptions' });

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <Box mt={rem(mediaQuery.md ? 20 : 0)}>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        {mediaQuery.md && (
                            <Grid item md={3} sx={{ ...css.grid.item }}>
                                <Box ml={-2}>
                                    <Box pl={rem(15)} pr={rem(15)} pb={rem(10)}>
                                        <ButtonSelectedFilter
                                            onClick={() => { }}
                                            title='Parceiro'
                                            value={
                                                <Image
                                                    src='https://carreiras.magazineluiza.com.br/img/magalu-banner.jpg'
                                                    alt='logo parceiro'
                                                    maxHeight={21}
                                                />
                                            } />
                                    </Box>
                                    {categories.map(({ id, title }, i, list) => (
                                        <React.Fragment key={id}>
                                            <MenuItemComponent
                                                size={'tiny'}
                                                title={title}
                                                onClick={() => { }} />
                                            {!(i + 1 === list.length) && (
                                                <Divider light />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Grid>
                        )}
                        <Grid item md={9}>
                            <Display.BreadcrumbsList list={[]} />
                            {!mediaQuery.md && (
                                <Box pt={rem(15)}>
                                    <ButtonSelectedFilter
                                        onClick={() => { }}
                                        title='Parceiro'
                                        value={
                                            <Image
                                                src='https://i.shoppingsmiles.com.br/imagens/v2/fornecedor0casasbahia.png'
                                                alt='logo parceiro'
                                                maxHeight={21}
                                            />
                                        } />
                                </Box>
                            )}

                            <Box mt={rem(15)}>
                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                    <Typography
                                        color="darkGray"
                                        fontSize={rem(13)}
                                        fontWeight={typography.fontWeightSemiBold}
                                    >
                                        Encontramos 1024 produto(s)
                                    </Typography>

                                    <Button
                                        variant='outlined'
                                        sx={{ ...css.button.relevants }}
                                        {...bindHover({
                                            ...popupState,
                                        })}>
                                        <Stack
                                            direction='row'
                                            alignItems='center'
                                            justifyContent='space-between'
                                            spacing={1}>
                                            <Typography
                                                color="mediumVioletRed"
                                                fontSize={rem(12)}
                                                fontWeight={typography.fontWeightMedium}
                                            >
                                                Mais relevantes
                                            </Typography>
                                            <I
                                                icon={['fas', 'chevron-down']}
                                                fontSize={rem(12)} />
                                        </Stack>
                                    </Button>
                                </Stack>
                            </Box>
                            <Box mt={rem(15)}>
                                <Grid container spacing={2}>
                                    {slickData.home.products(mediaQuery.sm ? 9 : 10).map((item) => (
                                        <Grid key={item._id} item md={4} sm={4} xs={6}>
                                            {/* <Product
                                                // product={item as any}
                                            // _id='655d10f4729d7ac31665f745'
                                            // idVariation='655d10f4729d7ac31665f747'
                                            // variationItemId='652ef455a2dca272da4c6cd0'
                                            // type={'REDEMPTION'}
                                            // to={item.to}
                                            // title={item.title}
                                            // value={item.value}
                                            // discount={item.discount}
                                            // partnerLogo={item.partnerLogo}
                                            // image={item.image}
                                            /> */}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Stack direction='row' justifyContent='center' mt={rem(20)}>
                                <Button
                                    type='button'
                                    variant='contained'
                                    size='large'>
                                    Carregar mais
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>

                <HoverPopover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box>
                        <Paper>
                            <MenuList>
                                {filterPageOptions.map(({ _id, name }, i) => (
                                    <MenuItem
                                        key={_id}
                                        onClick={() => { }}>
                                        <Typography
                                            variant="inherit"
                                            fontSize={rem(13)}
                                            fontWeight={!(i + 1 === 1) ?
                                                typography.
                                                    fontWeightSemiBold :
                                                typography.fontWeightBold}>
                                            {name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Paper>
                    </Box>
                </HoverPopover>
            </Box>
        </Box>
    );
};

export { Partner };
