import { alpha, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 99,
            backgroundColor: alpha(palette.common.white, 0.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
    return { css };
};
