/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useAds } from '@hooks/ads';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';
import { useUser } from '@hooks/user';
import { LoadServicesProps } from '@interfaces/Services';
import { StoreType } from '@types';


export const useLoad = () => {
    const userApi = useUser();
    const layoutApi = useLayout();
    const globalApi = useGlobal();
    const ad = useAds();

    const servicesLoad = async ({
        loadList, forceLoad, application,
    }: LoadServicesProps) => {
        const cookieSession = global ?
            await globalApi.app.cookies.get('session') :
            undefined;
        const storeTypeLStorageKey = localStorage.getItem('storeType') as StoreType;

        if (storeTypeLStorageKey) {
            globalApi.app.view.set(storeTypeLStorageKey as StoreType);
        } else {
            globalApi.app.view.set('SHOPPING');
        }

        const applicationType = application ? application.type : 'SHOPPING';

        if (loadList.includes('user') && cookieSession && !userApi.info.isLoaded) {
            await userApi.load({ jwt: cookieSession.data.jwt });
            // console.log('User is loaded!');
        }

        // eslint-disable-next-line max-len
        if (loadList.includes('cart') && (forceLoad && forceLoad.includes('cart') ? true : !userApi.cart.list.isLoaded)) {
            await userApi.cart.load({
                cartType: storeTypeLStorageKey ?? 'SHOPPING',
            });
            // console.log('Cart is loaded!');
        }
        if (loadList.includes('favorites') && (forceLoad && forceLoad.includes('favorites') ? true : !userApi.favorites.list.isLoaded && global)) {
            await userApi.favorites.load({
                favotireType: storeTypeLStorageKey ?? 'SHOPPING',
            });
            // console.log('Favorites is loaded!');
        }
        if (loadList.includes('layoutBannersCarrousel') &&
            !layoutApi.layout.banners.carrousel.list.isLoaded) {
            await layoutApi.layout.banners.carrousel.load();
            // console.log('Carrousel is loaded!');
        }
        if (loadList.includes('popularAds')) {
            await ad.ads.popular.load({
                viewType: storeTypeLStorageKey ?? 'SHOPPING',
            });
            // console.log('Ad popular is loaded!');
        }
        if (loadList.includes('adNews')) {
            await ad.ads.news.load({
                viewType: storeTypeLStorageKey ?? 'SHOPPING',
            });
            // console.log('Ad news is loaded!', applicationType);
        }
    };
    return { servicesLoad };
};

